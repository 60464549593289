@import "@paprika/stylers/lib/helpers.scss";
@import url("//fonts.googleapis.com/css?family=Lato:300,400,700,400italic");

* {
  box-sizing: border-box;
}

html {
  opacity: 1;
  visibility: visible;
}

body {
  background-color: $color--black-lighten-80;
  color: $color--black;
  font-family: $font-family--default;
  font-size: font-scale();
  line-height: line-height-scale();
  overflow: hidden;
}

html,
body,
#root,
#root > div {
  height: 100%;
  margin: 0;
}

.sriracha nav {
  padding: 0px 4px;
  background-color: #2f3b4d;
}

.sriracha.loading nav {
  padding-left: 48px;
}

.layout {
  display: flex;
  flex-flow: column;

  & > * {
    flex: 0 0 auto;
  }
}

#embeddingContainer {
  flex: 1 1 100%;
}
