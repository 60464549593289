.icon {
    background-color:#F6F0FA;
    width: 100%;
    height: 100%; 
    display: flex;
    align-items: center;
    justify-content: center 
}

.icon > svg {
    color: #853AB1
}

.title {
    display: flex
}

.title > span {
    margin-top: 4px
}