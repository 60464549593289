@import '~@paprika/tokens/lib/tokens.scss';

.no-access {
  width: 100%;
  margin-bottom: 0 !important;

  [data-wbi-icon='true'] {
    width: 100%;
    height: auto;
  }

  .description {
    margin: $space * 2 0;
  }
}

.no-access-card.no-access-card {
  display: block;
  margin: $space * 4 auto;
  max-width: 670px;
  padding: $space * 2;
  width: 100%;
}